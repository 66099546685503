<template>
  <p v-if="!user">No user found</p>
  <p v-else>{{ user.name }} logged in using {{ user.preferred_username }}</p>
</template>
<script>
import { msalInstance } from "vue-msal-browser";
import { default as msalPlugin } from "vue-msal-browser";
import Vue from "vue";
export default {
  data() {
    return {
      user: null,
      userToken: null,
      conf: null,
    };
  },
  mounted() {
    this.makeConfig();
  },

  methods: {
    async makeConfig() {
      this.getConfiguration();
    },
    async getConfiguration() {
      this.$http
        .get("sso-config")
        .then(async (responser) => {
          this.conf = responser.data;
          Vue.use(msalPlugin, responser.data);
          this.user = null;
          if (typeof msalInstance !== "undefined" && msalInstance) {
            let accounts = msalInstance.getAllAccounts();
            console.log(accounts);
            if (accounts[0]) {
              this.showLoader("Trying to login");
              let response = await msalInstance.getSilentToken(accounts[0]);
              this.user = response?.idTokenClaims;
              this.userToken = response?.idToken;
              let data = {
                user: this.user,
                token: this.userToken,
              };
              console.log("this.user");
              console.log(this.user);
              this.$store
                .dispatch("loginAD", data)
                .then((res) => {
                  if (res.status == 200 && res.data.status) {
                    this.hideLoader();
                    this.showSuccess("Authentication Success.");
                    this.findFirstPage();
                  }
                })
                .catch(async (error) => {
                  this.errorChecker(error);
                  // await this.$msal.authenticateRedirect();
                  this.$router.push({ name: "Login" });
                });
            } else {
              let urlString = window.location.href;
              let paramString = urlString.split("#")[1];
              let queryString = new URLSearchParams(paramString);
              this.getToken(queryString);
            }
          } else {
            let urlString = window.location.href;
            let paramString = urlString.split("#")[1];
            let queryString = new URLSearchParams(paramString);
            this.getToken(queryString);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getToken(queryString) {
      this.showLoader("Loading your details");
      let accounts = msalInstance.getAllAccounts();
      if (accounts[0]) {
        try {
          // let tokenResponse = await msalInstance.acquireTokenSilent(request);
          let response = await msalInstance.getSilentToken(accounts[0]);
          this.user = response?.idTokenClaims;
        } catch (error) {
          console.error(
            "Silent token acquisition failed. Using interactive mode"
          );
          let response = await msalInstance.acquireTokenPopup(accounts[0]);
          this.user = response?.idTokenClaims;
        }
        // let response = await msalInstance.getSilentToken(accounts[0]);
        // this.user = response?.idTokenClaims;
        if (this.user) {
          this.hideLoader();
        }
      } else {
        // console.log("calling");
        try {
          await msalInstance
            //   .getSilentToken(queryString.get("code"))
            .ssoSilent(queryString.get("code"))
            .then(async (account) => {
              console.log("account");
              console.log(account);
              this.user = account?.idTokenClaims;

              if (this.user) {
                this.hideLoader();
              }
              // let response = await msalInstance.ssoSilent(
              //   account
              // );
              await msalInstance.getSilentToken(account);
            })
            .catch((error) => {
              // console.log(error);
              this.errorChecker(error);
              // this.$msal.authenticateRedirect();
              // msalInstance.authenticatePopup();
              // msalInstance.loginRedirect(msalInstance.loginRequest);
              this.$router.push({ name: "Login" });
            });
        } catch (error) {
          console.error(
            "Silent token acquisition failed. Using interactive mode"
          );
          let response = await msalInstance.acquireTokenPopup(accounts[0]);
          this.user = response?.idTokenClaims;
        }
      }
      if (!this.user) {
        // this.showLoader("Checking your credentials");
        this.showError("Cookie issue found, try without incognito");
        // this.$msal.logoutRedirect();
      } else {
        let data = {
          user: this.user,
          token: this.userToken,
        };
        this.$store
          .dispatch("loginAD", data)
          .then((res) => {
            if (res.status == 200 && res.data.status) {
              this.hideLoader();
              this.showSuccess("Authentication Success.");
              this.findFirstPage();
            }
          })
          .catch(async (error) => {
            this.errorChecker(error);
            // await this.$msal.authenticateRedirect();
            // this.$msal.authenticateRedirect();
            // msalInstance.loginRedirect(msalInstance.loginRequest);
            this.$router.push({ name: "Login" });
          });
      }
    },
  },
};
</script>
